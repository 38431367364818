<template>
    <v-autocomplete v-model="localUser.objectives" :items="availableObjectives" @change="checkMaxSelection" counter="5"
        label="Seleccionar" single-line :search-input.sync="search" class="rounded-lg" filled rounded multiple
        :rules="[maxSelection(5, 'objectives')]" chips>
        <template v-slot:no-data>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        No hay resultados para "<strong>{{ search }}</strong>". Presiona <kbd>enter</kbd>
                        para crear uno nuevo.
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<script>
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { logAuditEvent } from '@/error/audit.js';

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
        isCoachView: {
            type: Boolean,
            default: false,
        },
        isEvaluationView: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            menu: false,
            search: '',
            injuriesPanel: [],
            localUser: JSON.parse(JSON.stringify(this.user)),
            availableObjectives: [
                'Desarrollo de la fuerza',
                'Agilidad',
                'Resistencia',
                'Flexibilidad',
                'Velocidad',
                'Potencia',
                'Coordinación',
                'Hipertrofia',
                'Movilidad',
                'Estabilidad',
                'Equilibrio',
                'Postura',
                'Rehabilitación',
                'Prevención de lesiones',
                'Perder peso',
            ],
        };
    },
    computed: {
        isAuthorizedUser() {
            const userType = this.$store.state.Auth.token.claims.type;
            return ['superuser', 'admin', 'entrenador'].includes(userType);
        },
    },
    watch: {
        'localUser.objectives': {
            handler() {
                this.updateUserData();
            },
            deep: true,
        },
        'localUser.injuries': {
            handler() {
                this.updateUserData();
            },
            deep: true,
        },
    },
    methods: {
        checkMaxSelection() {


            if (this.localUser.objectives && this.localUser.objectives.length > 5) {
                this.localUser.objectives.pop()
            }
        },
        addInjury() {
            this.localUser.injuries.push({
                nombre: null,
                cuando: null,
                como: null,
                deporte: null,
                recuperado: false,
                observation: '',
            });
        },
        removeInjury(index) {
            this.localUser.injuries.splice(index, 1);
        },
        async updateUserData() {
            try {
                const db = getFirestore();
                const userRef = doc(db, 'users', this.user.id);

                await updateDoc(userRef, {
                    objectives: this.localUser.objectives || [],
                    injuries: this.localUser.injuries || [],
                });

                logAuditEvent(
                    'update',
                    this.$store.state.Auth.token.claims.user_id,
                    `User preferences updated ${JSON.stringify(this.localUser)}`
                );
            } catch (e) {
                logAuditEvent(
                    'error',
                    this.$store.state.Auth.token.claims.user_id,
                    `Error updating user preferences ${e.message}`
                );
                this.$notify({
                    type: 'error',
                    title: 'Error',
                    text: 'Error al actualizar los datos.',
                });
            }
        },
        maxSelection(max, field) {
            return (v) => {
                if (v.length <= max) return true;
                return `Máximo ${max} seleccionados.`;
            };
        },
    },
};
</script>

<style scoped>
.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__selections {
    padding-top: 26px !important;
}
</style>
